import React, { useEffect, useState, useRef } from 'react';
import { logout } from '../../actions/auth';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom'
import InfraMarkerService from '../../api/infraMarkerService';
import Loader from '../common/Loader';
import ReCAPTCHA from "react-google-recaptcha"
import ENV_CONFIG from '../../config';

// There are 3 ways to get to this component
// 1: An authenticated user may visit.  For this we should send a password reset email and let the user know they need to enter the code from the email
// 2: an unauthenticated user may have been directed here trying to reset password.  they should also enter the code from the email
// 3: an unauthenticated user may have clicked an email link to get here.  They should not have to enter the code 

function ChangePassword({ auth, logout }) {
   const [loading, setLoading] = useState(false);
   const [password, setPassword] = useState("");                // Password is the first entry of a users password
   const [confirmPassword, setConfirmPassword] = useState("");  // Confirm password is the second entry of a users password
   const [resetToken, setResetToken] = useState("");            // Reset token is the full token passed to the server to verify this reset can happen
   const [resetCode, setResetCode] = useState("");              // Reset code is the user entered portion of the token that was emailed to the user
   const [resetPrefix, setResetPrefix] = useState("");          // Reset code is the server generated portion of the token that was returned from the reset email send
   const [resetErrors, setResetErrors] = useState([]);          // Reset errors to show the user if the server responds with any
   const [showSuccess, setShowSuccess] = useState(false);       // Flag to show user a successful reset message
   const [captchaToken, setCaptchaToken] = useState(null);
   const captchaRef = useRef(null);
   function onRecaptcha(value) {
      setCaptchaToken(value);
   }
   const {
      userId,  // userId is set in a reset link emailed to the user.  If user clicks on link, userId and token are passed in.
      token,  // token is set in a reset link email to the user.  If user clicks on link, userid and token are passed in
      email,  // email is set if password reset happens from forgot password form along with partialToken
      partialToken // partialToken is set if password reset happens from forgot password form
   } = useParams();  
   const navigate = useNavigate();

   const onPasswordChange = (event) => {
      setPassword(event.target.value);
   }

   const onConfirmPasswordChange = (event) => {
      setConfirmPassword(event.target.value);
   }

   const onResetCodeChange = (event) => {
      const trimmed = event.target.value.trimStart().trimEnd();
      setResetCode(trimmed);
      setResetToken(resetPrefix + trimmed);
   }

   useEffect(() => {
      setResetPrefix(partialToken);
   }, [partialToken])

   useEffect(() => {
      if (userId && token) {
         // Set the full token if we have it
         setResetToken(token);
      } else if (!email && auth.access_token) {
         setLoading(true);
         // If this wasnt a link click or directed from the forgot page, we should send an email
         // with the auth token as user identifier
         InfraMarkerService.submitPasswordResetRequest(null, auth.access_token)
            .then((response) => {
               setLoading(false);
               setResetPrefix(response.data.token);
            })
            .catch(() => {
               // Unable to send email
               setLoading(false);
               setResetErrors(['Failure sending password reset email.']);
            });
      }
   }, [auth.access_token, token, userId, email]);

   useEffect(() => {
      setResetErrors([]);
   }, [password, confirmPassword, resetCode]);

   function submitPasswordChange(e) {
      e.preventDefault();
      
      InfraMarkerService.setPassword(password, resetToken, userId, email, auth.access_token, captchaToken)
         .then(() => {
            setShowSuccess(true);
            setTimeout(() => {
               if (auth.isAuthenticated) {
                  logout();
               }
               setTimeout(() => { navigate('/login'); }, 2500);
            } , 2500);
         })
         .catch(error => {
            if (error?.response?.data?.password_errors) {
               setResetErrors(error.response.data.password_errors);
               captchaRef.current?.reset();
            }
         });
   }

   return (
      loading ? <Loader message='Emailing your password reset code...  Please check your inbox...' /> :
      <div className='main-content'>
         <h2 className="page-title">Change Password</h2>
         <h3>A reset email has been sent to the address associated to this account.</h3>
         <form onSubmit={submitPasswordChange}>
         { (resetPrefix) ?
            <div className="form-group form-inline">
               <label htmlFor='resetCode'>
                  Please enter password reset code: &nbsp;<i className="bi-info-circle" role="img" title="6 digit code sent to your email"></i>
               </label>
               &nbsp;
               <input
                  className="form-control"
                  type="input"
                  id='resetCode'
                  name="resetCode"
                  onChange={onResetCodeChange}
                  value={resetCode}
               />
            </div> : <></>}
            <div>
               Password reset tips:
               <ul>
                  <li>Must be sufficiently different from your username and email.</li>
                  <li>Must be at least 8 alphanumeric characters.</li>
                  <li>May not be one of the top 1000 most common passwords.</li>
                  <li>May not be only numeric.</li>
               </ul>
            </div>
            <div className="form-group form-inline">
               <label htmlFor='password'>Please enter password:</label>
               <input
                  className="form-control"
                  type="password"
                  id='password'
                  name="password"
                  autoComplete="new-password"
                  onChange={onPasswordChange}
                  value={password}
               />
            </div>
            <div className="form-group form-inline">
               <label htmlFor='confirm-password'>Please confirm password:</label>
               <input
                  className="form-control"
                  type="password"
                  id='confirm-password'
                  name="confirm-password"
                  autoComplete="new-password"
                  onChange={onConfirmPasswordChange}
                  value={confirmPassword}
               />
            </div>
            <div className="form-group">
               {(password !== confirmPassword) ? <div className="alert alert-warning">The entered passwords do not match.</div> : <></> }
               {resetErrors.length > 0 ?
                  <ul className="alert alert-danger">
                     {resetErrors.map(error => <li key={error}>{error}</li> ) }
                  </ul> : <></> }
               {!showSuccess ?
                  <>
                     { (auth.access_token === null) ? <>
                        <ReCAPTCHA sitekey={ENV_CONFIG.RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={onRecaptcha} />
                     </> : <></> }
                     <button disabled={!password || password !== confirmPassword || (!resetCode && resetPrefix) || (ENV_CONFIG.RECAPTCHA_SITE_KEY !== null && captchaToken === null && auth.access_token === null)} type="submit" className="btn btn-primary">
                        Submit
                     </button>
                     &nbsp;
                     <Link className="btn btn-secondary" to='/'>Cancel</Link> 
                  </> : 
                  <div className='alert alert-success'>Your password has been successfully reset.  You will be redirected to the login page shortly...</div>}
            </div>
         </form>
      </div>
   );
}

const mapStateToProps = (state) => ({
   auth: state.auth,
});

export default connect(mapStateToProps, { logout })(ChangePassword);
import React, {  ChangeEvent, FormEvent, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import InfraMarkerService from '../../api/infraMarkerService';
import Loader from '../common/Loader';
import ReCAPTCHA from "react-google-recaptcha"
import ENV_CONFIG from '../../config';

export default function ResetPassword() {
   const [loading, setLoading] = useState(false);
   const [email, setEmail] = useState("");
   const navigate = useNavigate();
   const [captchaToken, setCaptchaToken] = useState<string|null>(null);
   const captchaRef = useRef(null);
   function onRecaptcha(value: string|null ) {
      setCaptchaToken(value);
   }

   const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
   }

   function submitPasswordReset(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();
      setLoading(true);
      InfraMarkerService.submitPasswordResetRequest(email, undefined, captchaToken)
         .then((response) => {
            setLoading(false);
            navigate(`/forgot-password/${email}/${response.data.token}`);
         })
         .catch(() => {
            // Invalid email.  Nothing to do.  Pretend we're OK so users or bots dont know it was a valid email.
            setLoading(false);
            navigate(`/forgot-password/${email}/${(new Date()).toString()}`);
         });
   }

   return (
      loading ? <Loader message='Emailing your password reset code...  Please check your inbox...' /> :
      <div className='main-content'>
         <h2 className="page-title">Reset Password</h2>
         <div>

         </div>
         <form onSubmit={submitPasswordReset}>
            <div className="form-group form-inline">
               <label htmlFor='email'>Please enter your email address: <span title='required' className="required-text">&nbsp;*</span></label>
               <input
                  className="form-control"
                  type="email"
                  id='email'
                  name="email"
                  onChange={onEmailChange}
                  value={email}
               />
            </div>

            <div>If your email address exists in our database, you will receive a password reset code at your email address in a few minutes.</div>
            <div>Please enter the password reset code on the next screen.</div>
            <br />
            <div className="form-group">
               <ReCAPTCHA sitekey={ENV_CONFIG.RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={onRecaptcha} />
               <button disabled={!email || captchaToken === null} type="submit" className="btn btn-primary">
                  Submit
               </button>
               &nbsp;
               <Link className="btn btn-secondary" to='/'>Cancel</Link> 
            </div>
         </form>
      </div>
   );
}
